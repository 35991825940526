import { isDefined } from "@clipboard-health/util-ts";
import { useGetMissingRequirements } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { useGetMissingRequirementsForDate } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirementsForDate";
import { getDocumentCheckQualificationFromMultipleQualifications } from "@src/appV2/Accounts/Documents/helpers";
import type { HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { logError } from "@src/appV2/lib/analytics";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import {
  type BookabilityStatusItem,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type QueryClient, useQuery } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";

import type { WorkplaceProfile } from "../../Workplace/types";
import type { ShiftWithType } from "../types";

interface UseGetMissingRequirementsForShift {
  shift?: ShiftWithType;
  workplace?: WorkplaceProfile;
  bookabilityStatus?: BookabilityStatusItem;
  selectedWorkerQualifications: string[];
}

const MISSING_REQUIREMENTS_FOR_SHIFT_QUERY_KEY = "missingRequirementsForShift";

export async function invalidateMissingRequirementsForShift(
  queryClient: QueryClient
): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [MISSING_REQUIREMENTS_FOR_SHIFT_QUERY_KEY] });
}

export function useGetMissingRequirementsForShift(props: UseGetMissingRequirementsForShift) {
  const { shift, workplace, bookabilityStatus, selectedWorkerQualifications } = props;

  const qualificationForDocumentCheck = getDocumentCheckQualificationFromMultipleQualifications({
    shiftQualificationRequirement: shift?.agentReq,
    selectedWorkerQualifications,
  });

  // This should never happen, hence we log an error
  if (!qualificationForDocumentCheck && isDefined(shift?.agentReq)) {
    logError("No qualification for document check found for shift", {
      metadata: {
        shift,
        selectedWorkerQualifications,
      },
      error: new Error("No qualification for document check found for shift"),
    });
  }

  const worker = useDefinedWorker();

  const hasMissingRequirements = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS,
  ]);

  // Used to get general missing and pending documents for the worker
  const {
    data: requirementsData,
    isInitialLoading: isWorkerMissingDocumentsLoading,
    missingDocuments: missingDocumentsSet,
    pendingDocuments: pendingDocumentsSet,
    isFetching: isFetchingMissingRequirements,
  } = useGetMissingRequirements(
    {
      workerId: worker.userId,
      workplace: {
        id: workplace?.userId,
        type: workplace?.type,
        msa: workplace?.fullAddress?.metropolitanStatisticalArea,
        state: workplace?.fullAddress?.state,
        region: workplace?.fullAddress?.region,
      },
      ...(qualificationForDocumentCheck ? { qualifications: [qualificationForDocumentCheck] } : {}),
    },
    {
      enabled: isDefined(shift) && isDefined(workplace) && hasMissingRequirements,
    }
  );

  /**
   * Missing requirements specifically for the shift's date.
   * Useful for documents that are going to be expired soon, which are not
   * included in the general missing documents query.
   */
  const expiringDocumentsSet = new Set<HcpRequirement>();

  // We want to fetch requirements as soon and as frequently as possible.
  // We also don't want to use the underlying graphql mutation directly as it's deprecated.
  const { mutateAsync: getMissingRequirementsForDate } = useGetMissingRequirementsForDate();
  const {
    data: missingRequirementsForShift,
    isInitialLoading: isGetMissingRequirementsLoading,
    isFetching: isFetchingMissingRequirementsForDate,
  } = useQuery({
    queryKey: [MISSING_REQUIREMENTS_FOR_SHIFT_QUERY_KEY, workplace?.userId, shift?.end],
    queryFn: async () =>
      await getMissingRequirementsForDate({
        hcfId: workplace?.userId ?? "",
        date: shift?.end ?? "",
        qualification: qualificationForDocumentCheck ?? "",
      }),
    enabled: isDefined(shift) && isDefined(workplace),
    refetchInterval: minutesToMilliseconds(5),
    refetchIntervalInBackground: true,
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
    refetchOnReconnect: "always",
  });

  const requirements = requirementsData?.data.hcpRequirementStatus.requirements ?? [];
  const missingDocuments = [...missingDocumentsSet];
  const pendingDocuments = [...pendingDocumentsSet];

  missingRequirementsForShift?.data.hcpMissingRequirementsForDate.forEach((requirement) => {
    const isInMissingDocumentsSet = missingDocuments.some(
      (missingDocument) => missingDocument.reqId === requirement.reqId
    );
    const isInPendingDocumentsSet = pendingDocuments.some(
      (pendingDocument) => pendingDocument.reqId === requirement.reqId
    );

    if (isInMissingDocumentsSet || isInPendingDocumentsSet) {
      return;
    }

    // The expiry date does not exist in the response of the missing requirements for date query
    // so we need to use the requirements from the general missing requirements query
    const requirementWithExpiryDate = requirements.find(
      (requirementForShift) => requirementForShift.reqId === requirement.reqId
    );

    // If the document is not in the missing or pending documents set, it's expiring
    expiringDocumentsSet.add(requirementWithExpiryDate ?? (requirement as HcpRequirement));
  });

  const isLoading = isWorkerMissingDocumentsLoading || isGetMissingRequirementsLoading;
  const isFetching = isFetchingMissingRequirements || isFetchingMissingRequirementsForDate;

  return {
    isLoading,
    expiringDocumentsSet,
    missingDocumentsSet,
    pendingDocumentsSet,
    isFetching,
  };
}
