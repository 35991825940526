import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type GeoLocation } from "@src/appV2/Location";
import { useClaimShift } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { useMutation } from "@tanstack/react-query";

import { useShiftDiscoverySearchMode } from "../../ShiftDiscovery/useShiftDiscoverySearchMode";

interface UseBookShiftProps {
  shiftId: string;
  shiftOfferId?: string;
  onBook: () => void;
}

/**
 * This hook is used to provide an easy entrance to book a shift. This assumes that the device
 * already has geolocation and any other ackowledgements or checks have been completed. It is the
 * caller's responsibility to ensure that the shift is in a bookable state before attempting to
 * book the shift
 * @param props takes in the shift to book, and a callback to call once the booking is successful
 * @returns
 */
export function useBookShift(props: UseBookShiftProps) {
  const { onBook, shiftId, shiftOfferId } = props;
  const searchMode = useShiftDiscoverySearchMode();
  const { showErrorToast } = useToast();

  const { mutateAsync: claimShift } = useClaimShift();

  const { mutateAsync: attemptBookingShift, isLoading: isBooking } = useMutation(
    async (geoLocation?: GeoLocation) => {
      const { latitude, longitude } = geoLocation ?? {};
      if (!shiftOfferId) {
        showErrorToast(
          "Could not load price for booking this shift. Please re-open the app and try again."
        );
        return;
      }

      try {
        await claimShift({
          shiftId,
          offerId: shiftOfferId,
          searchMode,
          latitude,
          longitude,
        });

        logEvent(APP_V2_USER_EVENTS.INSTANT_BOOKED_SHIFT, { shiftId });

        onBook();
      } catch (error: unknown) {
        const errorMessage =
          (error as { response?: { data?: { displayableMessage?: string } } }).response?.data
            ?.displayableMessage ??
          "Something went wrong while booking this shift. Please try again.";
        showErrorToast(errorMessage);
      }
    }
  );

  return {
    attemptBookingShift,
    isBooking,
  };
}
